@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background_head_anim {
    position: relative;
    width: 90vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: #4f84c9;
    overflow: hidden;
    border-radius: 25px;
}
.background_head_anim li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 19s linear infinite;
}

.background_head_anim li:nth-child(0) {
    left: 47%;
    width: 116px;
    height: 116px;
    bottom: -116px;
    animation-delay: 1s;
}
.background_head_anim li:nth-child(1) {
    left: 78%;
    width: 176px;
    height: 176px;
    bottom: -176px;
    animation-delay: 3s;
}
.background_head_anim li:nth-child(2) {
    left: 59%;
    width: 120px;
    height: 120px;
    bottom: -120px;
    animation-delay: 4s;
}
.background_head_anim li:nth-child(3) {
    left: 54%;
    width: 79px;
    height: 79px;
    bottom: -79px;
    animation-delay: 1s;
}
.background_head_anim li:nth-child(4) {
    left: 73%;
    width: 164px;
    height: 164px;
    bottom: -164px;
    animation-delay: 17s;
}
.background_head_anim li:nth-child(5) {
    left: 70%;
    width: 120px;
    height: 120px;
    bottom: -120px;
    animation-delay: 15s;
}
.background_head_anim li:nth-child(6) {
    left: 68%;
    width: 148px;
    height: 148px;
    bottom: -148px;
    animation-delay: 21s;
}
.background_head_anim li:nth-child(7) {
    left: 16%;
    width: 125px;
    height: 125px;
    bottom: -125px;
    animation-delay: 13s;
}
.background_head_anim li:nth-child(8) {
    left: 34%;
    width: 141px;
    height: 141px;
    bottom: -141px;
    animation-delay: 16s;
}
.background_head_anim li:nth-child(9) {
    left: 41%;
    width: 89px;
    height: 89px;
    bottom: -89px;
    animation-delay: 38s;
}
.background_head_anim li:nth-child(10) {
    left: 57%;
    width: 129px;
    height: 129px;
    bottom: -129px;
    animation-delay: 42s;
}
.background_head_anim li:nth-child(11) {
    left: 27%;
    width: 65px;
    height: 65px;
    bottom: -65px;
    animation-delay: 43s;
}
.background_head_anim li:nth-child(12) {
    left: 52%;
    width: 101px;
    height: 101px;
    bottom: -101px;
    animation-delay: 7s;
}
.background_head_anim li:nth-child(13) {
    left: 67%;
    width: 153px;
    height: 153px;
    bottom: -153px;
    animation-delay: 45s;
}
.background_head_anim li:nth-child(14) {
    left: 53%;
    width: 125px;
    height: 125px;
    bottom: -125px;
    animation-delay: 46s;
}
.background_head_anim li:nth-child(15) {
    left: 53%;
    width: 128px;
    height: 128px;
    bottom: -128px;
    animation-delay: 41s;
}
.background_head_anim li:nth-child(16) {
    left: 70%;
    width: 117px;
    height: 117px;
    bottom: -117px;
    animation-delay: 18s;
}
.background_head_anim li:nth-child(17) {
    left: 58%;
    width: 134px;
    height: 134px;
    bottom: -134px;
    animation-delay: 3s;
}
.background_head_anim li:nth-child(18) {
    left: 14%;
    width: 88px;
    height: 88px;
    bottom: -88px;
    animation-delay: 13s;
}
.background_head_anim li:nth-child(19) {
    left: 82%;
    width: 95px;
    height: 95px;
    bottom: -95px;
    animation-delay: 19s;
}