@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* font-family: 'Open Sans', sans-serif; */
/* font-family: 'Poppins', sans-serif; */

.dropdown-menu {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.NevMenu .dropdown-menu {
  --bs-dropdown-border-radius: 0rem !important;
  --bs-dropdown-padding-y: 0px !important;

  margin-top: 118%;
  margin-right: 0px;

  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
  position: relative;
}
.NevMenu .dropdown-toggle[aria-expanded="true"]::before {
  content: "";
  background-color: rgb(61, 168, 240);
  width: calc(100% + 10px);
  height: 4px;
  position: absolute;
  bottom: calc(0px - 35%);
  left: -4px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.dropdown-menu .dropdown-item {
  height: 6vh;
  padding-top: 9px;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #f08080;
  color: #ffffff;
  padding-left: 25px;
  font-weight: 600;
}
.dropdown-menu .dropdown-item:last-child:hover {
  background-color: #f08080;
  color: #ffffff;
  border-end-end-radius: 8px;
  border-end-start-radius: 8px;
}

.NevMenu {
  justify-content: center;
  align-items: center;
  gap: 25px;
  /* width: 100%; */
  width: 100%;
  height: 10vh;
}
.NevMenu .nav-link {
  display: block;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /* width: 4rem; */
  /* height: 20px; */
  text-align: center;
  position: relative; /* Add this to establish a positioning context */
}
.NevMenu .nav-link.dropdown-toggle::after {
  border-top: none;
  border-right: none;
  /* border-bottom: 0;
  border-left: .3em solid transparent; */
}

.NevMenu .nav-link::after {
  content: "";
  background-color: #f08080;
  width: 4px;
  height: 4px;
  position: absolute;
  right: -10%;
  bottom: 2.5vh;
  /* right: -30%;
  bottom: 0.9%; */

  border-radius: 50px;
}

.NevMenu .nav-link.active::before {
  content: "";
  background-color: #3ea9f0;
  width: calc(100% + 10px);
  height: 4px;
  position: absolute;
  /* bottom: 0px; */
  bottom: calc(0px - 35%);
  left: -4px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}

.NevMenu .nav-link::before {
  content: "";
  background-color: #3ea9f0;
  width: calc(100% + 10px);
  height: 0px;
  position: absolute;
  /* bottom: 0px; */
  bottom: calc(0px - 35%);
  left: -4px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  transform-origin: bottom top;
  transition: height 0.2s ease-in;
}
.NevMenu .nav-link:hover:before {
  height: 4px;
  transform-origin: bottom center;
}

.navbar-toggler {
  border: none;
}
.navbar-toggler:is(:focus, :hover, :active) {
  background-color: #f08080;
  border: none;
  box-shadow: none;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(61, 168, 240)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
.navbar-toggler:is(:focus, :hover, :active) .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}
.unwanted {
  width: 36%;
  height: 10vh;
}
.account_image {
  width: 14%;
  text-align: center;
  border-radius: 25px;
}

.account_Flex {
  /* background-color: rgb(255, 255, 255) */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;

  /* height:"10vh" !important; */
}
.account_Flex .cart {
  color: #3ea9f0;
  margin-left: 10px;
  position: relative;
  /* display: block; */
}
.account_Flex .cart.Added::after {
  content: "";
  background-color: #f08080;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -3px;
  right: -5px;
  border-radius: 50px;
}
.account_Flex .Join_btn {
  border-radius: 5px;
  font-weight: 600;
  width: 15vw;
  color: #3fabf3;
  border-color: #3fabf3;
}
.account_Flex .Join_btn:is(:focus, :hover, :active) {
  width: 15vw;
  color: #ffffff;
  background-color: #3fabf3;
  box-shadow: 0px 5px 10px #3fabf37d;
}

.navbar-brand {
  width: 12%;
}

.btn_primary {
  color: #3fabf3;
  border-color: #3fabf3;
}

header {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
  background-image: url("./Components/images/banner-img.png");
}

.DocTrack_Footer {
  background-color: #e8f6ff;
  margin-top: 20px;
}
.DocTrack_Footer h5 {
  font-family: "Poppins", sans-serif;
  color: #3d4461;
  font-weight: 400;
  font-size: 18px;
}

.DocTrack_Footer .nav-link,
a {
  color: #3d4461;
  font-family: "Open Sans", sans-serif;
}
.DocTrack_Footer .nav-link {
  font-size: 14px;
  font-weight: 400;
  position: relative;
}
.DocTrack_Footer .Options .nav-link:hover::after {
  content: "";
  background-color: #f08080;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 5px;
  bottom: 1.3vh;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.DocTrack_Footer .Options .nav-link::after {
  content: "";
  background-color: #f08080;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 5px;
  bottom: 1.3vh;
  opacity: 0;
  transition: opacity 0.2s ease-in, transform 0.2s ease-in;
}
.DocTrack_Footer .Options .nav-link {
  /* margin-left: 4%; */
  padding: 0px 0px 0px 0px;
  transition: padding 0.2s ease-in;
}
.DocTrack_Footer .Options .nav-link:hover {
  /* margin-left: 4%; */
  padding: 0px 0px 0px 15px;
}

.btn-outline-Subscribe {
  border-color: #3d4461;
  color: #3d4461;
  box-shadow: none;
}
.btn-outline-Subscribe:is(:focus, :hover, :active) {
  background-color: #3d4461;
  color: #ffffff;
}
.DocTrack_Footer .section:nth-child(1) .nav-link:hover {
  color: #f08080;
}

/** HeadSlider_Text */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

.HeadSlider_Text {
  width: 50%;
}

.HeadSlider_Text h1:nth-child(1) {
  font-style: normal;
  font-variant-caps: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 104px;
  font-family: "Poppins", sans-serif;
  color: #3d44611a;

  font-size: 120px;
}
.HeadSlider_Text h1:nth-child(1) span {
  font-family: "Open Sans", sans-serif;
  font-size: 170px;
}
.HeadSlider_Text h1:nth-child(2),
.HeadSlider_Text h1:nth-child(4) {
  color: #ff5851;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 37px;
}
.HeadSlider_Text h1:nth-child(3) {
  margin: 0;
  font-size: 60px;
  font-weight: 700;
  line-height: 73px;
  color: #3d4461;
}
.WaterPark {
  font-size: 500px;
  line-height: 104px;
  font-family: "Poppins", sans-serif;
  color: #3d44611a;
  font-style: normal;
  font-variant-caps: normal;
  font-weight: 300;
  font-stretch: normal;
}
.SliderHeight {
  height: 100vh;
  /* height: 90vh; */
}
.swiper-button-prev {
  display: none !important;
}
.swiper-button-next {
  display: none !important;
}
.PreBlock {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /* overflow: hidden; */
  z-index: 2;
  /* border: 0.5px solid #3d4461; */

  display: grid;
  place-content: center;
}
.PreBlock div {
  background-color: #ffffff;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;

  font-weight: 700;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: rgba(61, 60, 60, 0.548);
  text-align: end;

  width: 80px;
  /* height: 45px; */
  padding: 0px 23px 0px 0px;
  /* margin-top: 250%; */

  transition: transform 0.2s ease-in, width 0.2s ease-in;
  position: relative;
}
.PreBlock:hover div {
  transform: translateX(30px);
  box-shadow: 2px 2px 25px #6060604f;
  background-color: #ffffff;
  color: #3d4461;
  border-radius: 25px;
  width: 100px;
}
.PreBlock .ic {
  display: none;
}
.PreBlock:hover .ic {
  display: inline-block;
  position: absolute;
  top: 26%;
  right: 60%;
  bottom: 0;
  left: 0;
  color: #f08080;
  transition: display 0.2s ease-in;
}
.NextBlock {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  /* overflow: hidden; */

  z-index: 2;
  /* border: 0.5px solid #3d4461; */

  display: grid;
  place-content: center;
}
.NextBlock div {
  background-color: #ffffff;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;

  font-weight: 700;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: rgba(61, 60, 60, 0.548);
  text-align: start;

  width: 80px;
  /* height: 45px; */
  padding: 0px 0px 0px 23px;
  margin-bottom: 35px;

  transition: transform 0.2s ease-in, width 0.2s ease-in;
  position: relative;
}
.NextBlock:hover div {
  transform: translateX(-30px);
  box-shadow: 2px 2px 25px #6060604f;
  background-color: #ffffff;
  color: #3d4461;
  border-radius: 25px;
  width: 100px;
}
.NextBlock .ic {
  display: none;
}
.NextBlock:hover .ic {
  display: inline-block;
  position: absolute;
  top: 26%;
  right: 0;
  bottom: 0;
  left: 60%;
  color: #f08080;
  transition: display 0.2s ease-in;
}

/** HeadSlider_Text */

/*? DocInfo */
.doc_des {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 6px;
  color: #3d4461;
}
.MainHead {
  color: #3d4461;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
}
.MainHead span {
  color: #ff5851;
}
.SubHead {
  color: #ff5851;
  font-weight: 500;
  font-size: 25px;
  /* line-height: 45px; */
}
.doc_img::before {
  top: 20px;
  left: 20px;
  content: "";
  width: 100%;
  z-index: -1;
  height: 100%;
  position: absolute;
  background: url("./Components//images/img_bg/img-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.doc_Name {
  /* height: 18%; */
  /* width: 37.5%; */
  margin-right: 10px;
  color: #ffffff;
  background-color: #3d4461;
}
.doc_Name div {
  padding: 30px 24px 30px 26px;
}
.doc_Name div::after {
  position: absolute;
  content: "";
  right: -10px;
  bottom: 0;
  height: 100%;
  background-color: #ff5851;
  width: 5px;
}

/* .doc_img_com::after {
  position: absolute;
  left: 0;
  content: "";
  width: 588px;
  height: 303px;
  bottom: -205px;
  z-index: 99;
  background: url("./Components/images/img_bg/section-bg.png");

} */

/*? DocInfo */

/** Steps-com  */

.Steps-com {
  background-color: #e8f6ff;
  /* height: 100vh; */
  width: 100%;
  padding: 80px 0px 80px 0px;
  /* justify-content: space-around; */
}
.Steps-com h1 span {
  color: #ff5851;
}
.Steps_flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.Steps_flex div {
  flex-grow: 3 2 1;
}
.Steps_flex div .imgP {
  border: 2px dashed #3d44615f;
}
.StepHead h5 {
  color: #3d4461;
  font-size: 21px;
  font-weight: 600;
}
.StepHead p {
  color: #3d4461;
  font-size: 17px;
  font-weight: 600;
}
.blockImg {
  position: relative;
}
.blockImg::after {
  position: absolute;
  content: "";
  top: 10%;
  right: -102%;
  width: 110%;
  height: 102%;
  /* background-color: #3d4461; */
  background-image: url("./Components/images/line-02.png");
  z-index: 30;
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
}
.blockImg::before {
  position: absolute;
  content: "";
  top: 10%;
  left: -95%;
  width: 110%;
  height: 102%;
  /* background-color: #3d4461; */
  background-image: url("./Components/images/line-02.png");
  z-index: 30;
  /* background-size: 100% 100%; */
  background-repeat: no-repeat;
}

/** Steps-com  */

/*? Calender */

.fc-header-toolbar .fc-toolbar-chunk:nth-child(1) .fc-button-group {
  display: none !important;
  /* background-color: #3ea9f0 !important; */
}
.fc-daygrid-event-harness .fc-event {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.fc-toolbar-title {
  color: #3d4461;
  /* text-overflow: ellipsis; */
}

/*? Calender */

/** Buttons Css  */

/*? Primary Button */
.Prime_btn {
  border-radius: 5px;
  font-weight: 600;
  /* width: 12vw; */
  width: 100%;
  height: 8vh;
  color: #3d4461;
  background-color: transparent;

  border: 2px solid #3fabf3;
}
.Prime_btn:is(:focus, :hover, :active) {
  color: #ffffff;
  background-color: #3fabf3;
  box-shadow: 0px 5px 10px #3fabf37d;
  border: 2px solid #3fabf3;
}
.Prime_btn2 {
  border-radius: 5px;
  font-weight: 600;
  width: 12vw;
  height: 8vh;
  color: #ffffff;
  border: 2px solid #3fabf3;
  background-color: #3fabf3;
}
.Prime_btn2:is(:focus, :hover, :active) {
  color: #ffffff;
  background-color: #3fabf3;
  box-shadow: 0px 5px 10px #3fabf37d;
  border-color: #3fabf3;
}

/** Buttons Css  */

.fade-in-left {
  -webkit-animation: fade-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.fade-in-right {
  -webkit-animation: fade-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*? Form Button  */
.hover-danger:hover {
  /* background-color: red !important; */
  border-color: red !important;
  color: red !important;
}
.bg-blue {
  background-color: #e8f6ff;
  color: #ffffff;
}
.Custom_Card:hover {
  box-shadow: 5px 5px 5px #00000057;
  border-color: #3d4461;
  /* color: #3d4461 !important; */
}
.Custom_Card {
  margin: 10px 10px 10px 10px;
}
.Custom_bg {
  background-color: #3d4461 !important;
}
.Custom_color {
  color: #3d4461 !important;
}
.Custom_color2 {
  color: #ff5851 !important;
}

.BrandImage {
  transform: scale(1.5);
}
/*? Form Button  */

/*! Special Classes  */

.text-justify {
  text-align: justify !important;
}

.fw-7 {
  font-weight: 700;
}
.fw-6 {
  font-weight: 600;
}
.fw-5 {
  font-weight: 500;
}

.fs-40 {
  font-size: 40px;
}
.fs-30 {
  font-size: 30px;
}
.fs-25 {
  font-size: 25px;
}
.fs-20 {
  font-size: 20px;
}
.fs-15 {
  font-size: 14px;
}

.fs-17{
  font-size: 17px;
}

/*! Special Classes  */

.custom_Padding {
  padding: "0rem 10rem";
}

/* Animation Key Frames */
/* Slide Top */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

/* fade-right */
@-webkit-keyframes fade-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-right {
  0% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
/* fade-left */
@-webkit-keyframes fade-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* media Qurry on 992 for top bar */

@media (max-width: 992px) {
  .navbar-collapse {
    display: flex;
    flex-direction: row;
  }
  .NevMenu {
    justify-content: start;
    align-items: start;
    gap: 2px;
    height: auto;
    width: 65%;
    padding-left: 20px;
    /* border: 1px solid #3ea9f0; */
  }
  .NevMenu .nav-link.active::before {
    width: 4px;
    height: 100%;

    bottom: 0px;
    left: -20px;
  }
  .NevMenu .nav-link::after {
    all: unset;
  }

  .NevMenu .nav-link::before {
    width: 0px;
    height: 100%;

    bottom: 0px;
    left: -20px;
    transform-origin: left center;
    transition: width 0.2s ease-in;
  }
  .NevMenu .nav-link:hover:before {
    width: 4px;
    height: 100%;
    transform-origin: left center;
  }
  .NevMenu .dropdown-toggle {
    text-align: start;
  }
  .NevMenu .dropdown-toggle[aria-expanded="true"]::before {
    /* Your CSS styles here */
    width: 4px;
    height: 100%;

    bottom: 0px;
    left: -20px;
  }

  .NevMenu .dropdown-menu {
    margin-top: 35%;
    /* width: 100%; */
    border: none;
  }

  .unwanted {
    width: 45%;
    /* border: 1px solid #f08080; */
    height: 100%;
  }
  .unwanted .account_Flex {
    height: auto;
    flex-direction: column;
    gap: 20px;
  }
  .unwanted .account_Flex div:nth-child(2) {
    order: -1;
    width: 40%;
    border-radius: 50%;
  }

  .unwanted .account_Flex div:nth-child(1) {
    /* background-color: #e5eff5; */
    display: none;
  }

  .navbar-brand {
    width: 20%;
  }
  .DocTrack_Footer .section:nth-child(2) {
    display: none;
  }
  .DocTrack_Footer .section:nth-child(3) {
    margin-left: 5%;
  }

  .HeadSlider_Text h1:nth-child(1) {
    font-size: 70px;
  }
  .HeadSlider_Text h1:nth-child(1) span {
    font-size: 70px;
  }
  .HeadSlider_Text h1:nth-child(2),
  .HeadSlider_Text h1:nth-child(4) {
    font-size: 35px;
  }
  .HeadSlider_Text h1:nth-child(3) {
    font-size: 22px;
  }
  .WaterPark {
    font-size: 250px;
  }
  .Prime_btn2 {
    width: 100% !important;
  }
  .Prime_btn {
    width: 100% !important;
  }
  .blockImg::after {
    width: 0%;
    height: 0%;
  }
  .blockImg::before {
    width: 0%;
    height: 0%;
  }
  .PreBlock {
    display: none;
  }
  .NextBlock {
    display: none;
  }
  .SliderHeight {
    /* height: 100vh; */
    height: 90vh;
  }
}

@media (max-width: 320px) {
  .NevMenu {
    width: 50%;
  }
  .unwanted {
    width: 50%;
  }
  .DocTrack_Footer .section:nth-child(3) {
    margin-left: 1%;
  }
  header {
    width: 100%;
    height: 55vh !important;
  }
  .SliderHeight {
    height: 55vh;
    /* border: 2px solid #3d4461; */
  }
}

@media (max-width: 720px) {
  .HeadSlider_Text {
    width: 100%;
  }
  .custom_Padding {
    padding: 0px 0px 0px 0pxs;
  }
}
